import { BaseApi } from '@/api/base-api'
import { RESOURCE_PROVINCES } from '@/shared/constants/resources'

class ProvincesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }
}

export default new ProvincesApi(RESOURCE_PROVINCES)